import React, { useState, useEffect, useRef, useCallback } from "react";
import { ethers } from "ethers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";
import {
  Code2,
  Wallet,
  Gem,
  ArrowUpCircle,
  Menu,
  X,
  ChevronRight,
  Upload,
  CheckCircle2,
  Send,
  ExternalLink,
} from "lucide-react";

import { TECTONIC_ORACLE_ABI } from "./abi/tectonic";

const PRICES_CONFIG = {
  basicSupport: { usd: 100, cro: 0, period: "month" },
  customDomain: { usd: 75, cro: 0, period: "one-time" },
  advancedSupport: { usd: 300, cro: 0, period: "month" },
  v2Upgrade: { usd: 500, cro: 0, period: "one-time" },
  adUnit: { usd: 50, cro: 0, period: "month" },
  adPack: { usd: 175, cro: 0, period: "month" },
  starterPack: { usd: 199, cro: 0, period: "one-time" },
  creditRemoval: { usd: 150, cro: 0, period: "one-time" },
  adsRemoval: { usd: 75, cro: 0, period: "one-time" },
};

const PROJECTS_DATA = {
  ongoing: [
    {
      id: 1,
      name: "RTRD Scan",
      description:
        "A #RTRD powerful tool for analyzing tokens and transactions. Check token info, price, liquidity, and transaction history.",
      image: "/img/rtrd.png",
      link: "https://rtrd-scanner.life",
      status: "Development",
    },
    {
      id: 2,
      name: "CM Gen2",
      description:
        "Welcome to the world of Crazzzy Monster! Exclusive Gen2 collection features 10,000 unique NFTs on the Cronos Blockchain.",
      image: "/img/CM.png",
      link: "https://crazzzy-monsters.netlify.app/",
      status: "Completed",
    },
    {
      id: 3,
      name: "FR33TOOLS",
      description:
        "Secure DeFi tools for Cronos Network. Break LP tokens and perform emergency withdrawals from farms safely and efficiently.",
      image: "/img/FR33TOOLS.png",
      link: "https://fr33tools.netlify.app/",
      status: "Completed",
    },
  ],
  demo: [
    {
      id: 4,
      name: "CRO Whispers",
      description:
        "A secure communication terminal for sending and receiving encrypted messages on chain",
      image: "/img/001.png",
      link: "https://cro-whispers.netlify.app/",
      status: "Demo",
    },
  ],
};

const FR33D3V = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState("home");
  const [animate, setAnimate] = useState(false);
  const [globalAccount, setGlobalAccount] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
      setAnimate(true);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  const menuItems = [
    { title: "Home", id: "home" },
    { title: "Rules", id: "rules" },
    { title: "Projects", id: "projects" },
    { title: "Pricing", id: "pricing" },
    {
      title: "Tools",
      id: "tools",
      external: true,
      url: "https://fr33tools.netlify.app/",
    },
    { title: "Contact", id: "contact" },
  ];

  const features = [
    {
      icon: <Code2 size={40} />,
      title: "Web3 Development",
      description: "Custom blockchain solutions built on Cronos",
    },
    {
      icon: <Wallet size={40} />,
      title: "DeFi Integration",
      description: "Smart contract development and integration",
    },
    {
      icon: <Gem size={40} />,
      title: "Premium Solutions",
      description: "From free V1 builds to advanced customization",
    },
  ];

  const ConnectWallet = () => {
    const [isConnecting, setIsConnecting] = useState(false);

    const handleWalletConnect = async (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (!window.ethereum) {
        window.open("https://metamask.io/download/", "_blank");
        return;
      }

      if (globalAccount) {
        setGlobalAccount("");
        return;
      }

      setIsConnecting(true);
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const accounts = await provider.send("eth_requestAccounts", []);
        if (accounts.length > 0) {
          setGlobalAccount(accounts[0]);
        }
      } catch (error) {
        console.error("Error connecting wallet:", error);
      } finally {
        setIsConnecting(false);
      }
    };

    return (
      <button
        onClick={handleWalletConnect}
        disabled={isConnecting}
        className="inline-flex items-center gap-1.5 bg-gradient-to-r from-blue-500 to-purple-500 
          text-white rounded-lg hover:opacity-90 transition-all duration-300 
          disabled:opacity-50 disabled:cursor-not-allowed
          md:px-4 md:py-2 px-2 py-1.5 text-sm md:text-base"
      >
        <Wallet size={18} />
        {isConnecting ? (
          <div className="animate-spin rounded-full h-4 w-4 border-2 border-white/50 border-t-white md:ml-1" />
        ) : globalAccount ? (
          <>
            <span className="hidden md:inline">{`${globalAccount.slice(0, 6)}...${globalAccount.slice(-4)}`}</span>
            <span className="md:hidden">{`${globalAccount.slice(0, 4)}...`}</span>
          </>
        ) : (
          <span className="hidden md:inline">Connect</span>
        )}
      </button>
    );
  };

  const Navigation = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleMenuToggle = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsMenuOpen((prev) => !prev);
    };

    const handleNavigation = (pageId) => {
      if (pageId === "tools") {
        window.open("https://fr33tools.netlify.app/", "_blank");
      } else {
        setCurrentPage(pageId);
        setIsMenuOpen(false);
      }
    };

    return (
      <nav className="fixed top-0 left-0 right-0 z-50 bg-gradient-to-r from-blue-900/80 to-purple-900/80 backdrop-blur-md">
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            <div className="flex-shrink-0 flex items-center gap-2">
              <img src="img/logo.png" alt="Logo" className="h-8 w-auto" />
              <button
                onClick={() => handleNavigation("home")}
                className="text-2xl font-bold bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent"
              >
                FR33D3V
              </button>
            </div>

            <div className="hidden md:flex items-center space-x-8">
              <button
                onClick={() => handleNavigation("home")}
                className={`text-gray-300 hover:text-white transition-colors duration-300 
                  ${currentPage === "home" ? "text-white font-bold" : ""}`}
              >
                Home
              </button>
              <button
                onClick={() => handleNavigation("rules")}
                className={`text-gray-300 hover:text-white transition-colors duration-300 
                  ${currentPage === "rules" ? "text-white font-bold" : ""}`}
              >
                Rules
              </button>
              <button
                onClick={() => handleNavigation("projects")}
                className={`text-gray-300 hover:text-white transition-colors duration-300 
                  ${currentPage === "projects" ? "text-white font-bold" : ""}`}
              >
                Projects
              </button>
              <button
                onClick={() => handleNavigation("pricing")}
                className={`text-gray-300 hover:text-white transition-colors duration-300 
                  ${currentPage === "pricing" ? "text-white font-bold" : ""}`}
              >
                Pricing
              </button>
              <button
                onClick={() => handleNavigation("tools")}
                className="text-gray-300 hover:text-white transition-colors duration-300"
              >
                Tools
              </button>
              <button
                onClick={() => handleNavigation("contact")}
                className={`text-gray-300 hover:text-white transition-colors duration-300 
                  ${currentPage === "contact" ? "text-white font-bold" : ""}`}
              >
                Contact
              </button>
              <div className="flex-shrink-0">
                <ConnectWallet />
              </div>
            </div>

            <div className="flex items-center md:hidden">
              <div className="flex-shrink-0 mr-3">
                <ConnectWallet />
              </div>
              <button
                type="button"
                className="text-white p-2 focus:outline-none"
                onClick={handleMenuToggle}
                aria-label="Toggle menu"
              >
                {isMenuOpen ? (
                  <X size={24} className="block" />
                ) : (
                  <Menu size={24} className="block" />
                )}
              </button>
            </div>
          </div>

          {isMenuOpen && (
            <div className="md:hidden bg-gradient-to-r from-blue-900/95 to-purple-900/95 backdrop-blur-md">
              <div className="px-2 pt-2 pb-3 space-y-1">
                <button
                  onClick={() => handleNavigation("home")}
                  className="block w-full text-left px-3 py-2 text-white hover:bg-purple-800/50 rounded-md transition-colors duration-300"
                >
                  Home
                </button>
                <button
                  onClick={() => handleNavigation("rules")}
                  className="block w-full text-left px-3 py-2 text-white hover:bg-purple-800/50 rounded-md transition-colors duration-300"
                >
                  Rules
                </button>
                <button
                  onClick={() => handleNavigation("projects")}
                  className="block w-full text-left px-3 py-2 text-white hover:bg-purple-800/50 rounded-md transition-colors duration-300"
                >
                  Projects
                </button>
                <button
                  onClick={() => handleNavigation("pricing")}
                  className="block w-full text-left px-3 py-2 text-white hover:bg-purple-800/50 rounded-md transition-colors duration-300"
                >
                  Pricing
                </button>
                <button
                  onClick={() => handleNavigation("tools")}
                  className="block w-full text-left px-3 py-2 text-white hover:bg-purple-800/50 rounded-md transition-colors duration-300"
                >
                  Tools
                </button>
                <button
                  onClick={() => handleNavigation("contact")}
                  className="block w-full text-left px-3 py-2 text-white hover:bg-purple-800/50 rounded-md transition-colors duration-300"
                >
                  Contact
                </button>
              </div>
            </div>
          )}
        </div>
      </nav>
    );
  };

  const HomePage = () => (
    <div className="container mx-auto px-4 pt-20">
      <div
        className={`p-8 text-center transform transition-all duration-1000 ${
          animate ? "translate-y-0 opacity-100" : "translate-y-10 opacity-0"
        }`}
      >
        <h1 className="text-6xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
          FR33D3V
        </h1>
        <p className="text-xl mb-8 max-w-3xl mx-auto">
          Your go-to partner for innovative Web3 project development. We offer
          free and premium customized solutions to propel your project into the
          blockchain universe.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
        {features.map((feature, index) => (
          <div
            key={index}
            className="bg-gradient-to-br from-blue-800/50 to-purple-800/50 p-8 rounded-xl backdrop-blur-sm 
            transform transition-all duration-500 hover:scale-105 hover:shadow-xl text-center"
            style={{ transitionDelay: `${index * 150}ms` }}
          >
            <div className="flex justify-center mb-6">{feature.icon}</div>
            <h3 className="text-2xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
              {feature.title}
            </h3>
            <p className="text-gray-300 text-lg">{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );

  const RulesPage = () => (
    <div className="container mx-auto px-4 pt-24 max-w-4xl">
      <h2 className="text-4xl font-bold mb-12 text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
        Official Rules
      </h2>

      <div className="space-y-8">
        {[
          {
            title: "1. Eligibility Criteria for Free V1 Version",
            items: [
              "Showcase an innovative approach to Web3 or blockchain technologies",
              "Demonstrate active community engagement from project leaders",
              "Have visible backing from influential figures in the #Crofam or other major Web3 communities",
              "Pass our evaluation based on project feasibility, originality, and potential market impact",
            ],
          },
          {
            title: "2. Terms for Free V1 Version",
            items: [
              "Your project will be hosted on a FR33D3V-provided URL",
              "5 advertising spaces will be integrated into your site to support our free service",
              'A "Built by FR33D3V" credit will be displayed at the bottom of each page',
            ],
          },
          {
            title: "3. Available Customization Options",
            items: [
              "Choose your custom domain for a stronger brand presence",
              "Select your level of support from basic to advanced according to your needs",
              "Get V2 features to enhance your project functionalities",
              "Manage advertising spaces: buy individual units or packs for better visibility",
              "Remove our credit signature for complete brand control",
              "Get our Starter Bundle for an all-in-one solution",
              "All features payable in CRO at current market rates for the Cronos community",
            ],
          },
        ].map((section, index) => (
          <div
            key={index}
            className="bg-gradient-to-br from-blue-800/50 to-purple-800/50 p-8 rounded-xl"
          >
            <h3 className="text-2xl font-bold mb-6 text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
              {section.title}
            </h3>
            <ul className="space-y-4">
              {section.items.map((item, itemIndex) => (
                <li key={itemIndex} className="flex items-start gap-4">
                  <CheckCircle2
                    size={24}
                    className="text-green-400 mt-1 flex-shrink-0"
                  />
                  <span className="text-lg">{item}</span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]),
      )
      .join("&");
  };

  const PricingPage = () => {
    const [prices, setPrices] = useState(PRICES_CONFIG);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [formError, setFormError] = useState(null);
    const [paymentLoading, setPaymentLoading] = useState(false);
    const [transactionHash, setTransactionHash] = useState(null);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const PAYMENT_ADDRESS =
      process.env.REACT_APP_PAYMENT_ADDRESS ||
      "0x41E085c09dAfB733b647c6Bd856C1F4977d3b616";
    const RPC_URL =
      process.env.REACT_APP_CRONOS_RPC || "https://evm.cronos.org";
    const CRONOS_ORACLE_ADDRESS = "0x4636AC8216805Fe96dE9E7aFc62dA99096a930F6";
    const CRONOS_CHAIN_ID = "0x19";

    const updateCroPrices = useCallback(async () => {
      try {
        const provider = new ethers.providers.JsonRpcProvider(RPC_URL);
        const oracleContract = new ethers.Contract(
          CRONOS_ORACLE_ADDRESS,
          TECTONIC_ORACLE_ABI,
          provider,
        );

        const rawPrice = await oracleContract.latestAnswer();
        const priceInUSD = Number(rawPrice) / 1e8;

        if (priceInUSD > 0) {
          setPrices((prevPrices) =>
            Object.entries(prevPrices).reduce(
              (acc, [key, value]) => ({
                ...acc,
                [key]: {
                  ...value,
                  cro: (value.usd / priceInUSD).toFixed(4),
                },
              }),
              {},
            ),
          );
          setError(null);
        } else {
          throw new Error("Invalid price received");
        }
      } catch (err) {
        const FALLBACK_CRO_PRICE = 0.075;
        setPrices((prevPrices) =>
          Object.entries(prevPrices).reduce(
            (acc, [key, value]) => ({
              ...acc,
              [key]: {
                ...value,
                cro: (value.usd / FALLBACK_CRO_PRICE).toFixed(4),
              },
            }),
            {},
          ),
        );
        setError("Oracle connection failed. Using estimated prices.");
      } finally {
        setLoading(false);
      }
    }, [RPC_URL]);

    useEffect(() => {
      updateCroPrices();
      const interval = setInterval(updateCroPrices, 300000);
      return () => clearInterval(interval);
    }, [updateCroPrices]);

    const submitToNetlify = async (
      formData,
      selectedServices,
      croAmount,
      txHash = "",
    ) => {
      try {
        setFormError(null);
        const submissionData = {
          "form-name": "order-form",
          fullName: formData.fullName,
          email: formData.email,
          discordOrTelegram: formData.discordOrTelegram,
          projectName: formData.projectName,
          additionalNotes: formData.additionalNotes,
          selectedServices: selectedServices.join(", "),
          amountUSD: selectedServices.reduce(
            (total, service) => total + prices[service].usd,
            0,
          ),
          amountCRO: croAmount,
          transactionHash: txHash,
          timestamp: new Date().toISOString(),
        };

        const response = await fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode(submissionData),
        });

        if (!response.ok) throw new Error("Form submission failed");
        setFormSubmitted(true);
        return true;
      } catch (error) {
        console.error("Netlify form submission error:", error);
        setFormError("Failed to submit form. Please try again.");
        return false;
      }
    };

    const handlePayment = async (croAmount, selectedServices, formData) => {
      if (!globalAccount) {
        setError(
          "Please connect your wallet first to proceed with the payment.",
        );
        return;
      }

      if (!window.ethereum) {
        setError("Please install MetaMask to make payments");
        return;
      }

      setPaymentLoading(true);
      setError(null);

      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);

        await provider.send("eth_requestAccounts", []);
        const network = await provider.getNetwork();

        if (network.chainId !== parseInt(CRONOS_CHAIN_ID, 16)) {
          try {
            await window.ethereum.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: CRONOS_CHAIN_ID }],
            });
          } catch (switchError) {
            if (switchError.code === 4902) {
              await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainId: CRONOS_CHAIN_ID,
                    chainName: "Cronos Mainnet Beta",
                    nativeCurrency: {
                      name: "CRO",
                      symbol: "CRO",
                      decimals: 18,
                    },
                    rpcUrls: ["https://evm.cronos.org"],
                    blockExplorerUrls: ["https://cronoscan.com/"],
                  },
                ],
              });
            } else {
              throw switchError;
            }
          }
        }

        const signer = provider.getSigner();
        const address = await signer.getAddress();
        const balance = await provider.getBalance(address);
        const amountInWei = ethers.utils.parseEther(croAmount.toString());

        if (balance.lt(amountInWei)) {
          throw new Error("Insufficient CRO balance");
        }

        const tx = {
          to: PAYMENT_ADDRESS,
          value: amountInWei,
          data: ethers.utils.toUtf8Bytes(
            JSON.stringify({
              formData,
              services: selectedServices,
              timestamp: Date.now(),
            }),
          ),
          gasLimit: ethers.BigNumber.from(50000),
        };

        const transaction = await signer.sendTransaction(tx);
        const receipt = await transaction.wait(1);

        setTransactionHash(receipt.transactionHash);

        const formSubmitSuccess = await submitToNetlify(
          formData,
          selectedServices,
          croAmount,
          receipt.transactionHash,
        );

        if (!formSubmitSuccess) {
          throw new Error("Form submission failed after successful payment");
        }

        setFormSubmitted(true);
        alert(
          `✅ Order successfully submitted!\n\n` +
            `Amount: ${croAmount} CRO\n` +
            `Services: ${selectedServices.join(", ")}\n\n` +
            `View transaction: https://cronoscan.com/tx/${receipt.transactionHash}\n\n` +
            `You will receive a confirmation shortly.`,
        );
      } catch (err) {
        console.error("Payment error:", err);
        let errorMessage = "Payment failed: ";

        if (err.code === 4001) {
          errorMessage = "Transaction rejected by user";
        } else if (err.code === -32603) {
          errorMessage = "Please make sure you have enough CRO for gas fees";
        } else if (err.message.includes("insufficient funds")) {
          errorMessage = "Insufficient CRO balance";
        } else if (err.message.includes("network")) {
          errorMessage = "Please make sure you are connected to Cronos network";
        } else {
          errorMessage += err.message;
        }

        setError(errorMessage);
        setFormSubmitted(false);
      } finally {
        setPaymentLoading(false);
      }
    };

    const PriceCard = ({ title, items, children }) => {
      const [selectedServices, setSelectedServices] = useState([]);
      const [totalPrice, setTotalPrice] = useState({ usd: 0, cro: 0 });
      const [showForm, setShowForm] = useState(false);
      const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        discordOrTelegram: "",
        projectName: "",
        additionalNotes: "",
      });

      const calculateTotal = useCallback((selected) => {
        const total = selected.reduce(
          (acc, serviceKey) => ({
            usd: acc.usd + prices[serviceKey].usd,
            cro: acc.cro + parseFloat(prices[serviceKey].cro),
          }),
          { usd: 0, cro: 0 },
        );
        setTotalPrice(total);
      }, []);

      const handleServiceSelect = useCallback(
        (serviceKey) => {
          setSelectedServices((prev) => {
            const newServices = prev.includes(serviceKey)
              ? prev.filter((s) => s !== serviceKey)
              : [...prev, serviceKey];
            calculateTotal(newServices);
            return newServices;
          });
        },
        [calculateTotal],
      );

      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
      };

      const handleFormSubmit = async (e) => {
        e.preventDefault();
        await handlePayment(totalPrice.cro, selectedServices, formData);
      };

      const formatPeriod = (period) => {
        switch (period) {
          case "month":
            return "/month";
          case "year":
            return "/year";
          case "one-time":
            return " one-time";
          default:
            return "";
        }
      };

      if (showForm) {
        return (
          <div className="bg-gradient-to-br from-blue-800/50 to-purple-800/50 p-8 rounded-xl">
            <h3 className="text-2xl font-bold mb-6 text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
              Complete Your Order
            </h3>

            <div className="mb-6 p-4 bg-blue-900/30 rounded-lg">
              <h4 className="font-bold mb-2">Selected Services:</h4>
              {selectedServices.map((service) => (
                <div key={service} className="flex justify-between">
                  <span>{service.replace(/([A-Z])/g, " $1").trim()}</span>
                  <span>
                    ${prices[service].usd}
                    {formatPeriod(prices[service].period)}
                  </span>
                </div>
              ))}
              <div className="mt-2 pt-2 border-t border-blue-400/20 font-bold">
                Total: ${totalPrice.usd.toFixed(2)} ({totalPrice.cro.toFixed(4)}{" "}
                CRO)
              </div>
            </div>

            <form onSubmit={handleFormSubmit} className="space-y-4">
              <div>
                <input
                  type="text"
                  name="fullName"
                  placeholder="Full Name"
                  required
                  className="w-full bg-blue-900/30 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-purple-500"
                  value={formData.fullName}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  required
                  className="w-full bg-blue-900/30 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-purple-500"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <input
                  type="text"
                  name="discordOrTelegram"
                  placeholder="Discord or Telegram Username"
                  required
                  className="w-full bg-blue-900/30 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-purple-500"
                  value={formData.discordOrTelegram}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <input
                  type="text"
                  name="projectName"
                  placeholder="Project Name"
                  required
                  className="w-full bg-blue-900/30 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-purple-500"
                  value={formData.projectName}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <textarea
                  name="additionalNotes"
                  placeholder="Additional Notes (Optional)"
                  rows="3"
                  className="w-full bg-blue-900/30 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-purple-500"
                  value={formData.additionalNotes}
                  onChange={handleInputChange}
                />
              </div>

              <div className="flex space-x-4">
                <button
                  type="button"
                  onClick={() => setShowForm(false)}
                  className="flex-1 bg-gray-600 text-white font-bold py-2 px-4 rounded-lg hover:bg-gray-700 transition-colors duration-300"
                >
                  Back
                </button>
                <button
                  type="submit"
                  disabled={paymentLoading}
                  className="flex-1 bg-gradient-to-r from-blue-500 to-purple-500 text-white font-bold py-2 px-4 rounded-lg hover:opacity-90 transition-colors duration-300 disabled:opacity-50 flex items-center justify-center gap-2"
                >
                  {paymentLoading ? (
                    <>
                      <span>Processing...</span>
                      <div className="animate-spin rounded-full h-4 w-4 border-2 border-white/50 border-t-white" />
                    </>
                  ) : (
                    "Proceed to Payment"
                  )}
                </button>
              </div>
            </form>
          </div>
        );
      }

      return (
        <div
          className="bg-gradient-to-br from-blue-800/50 to-purple-800/50 p-8 rounded-xl backdrop-blur-sm
        transform transition-all duration-500 hover:scale-105 hover:shadow-xl"
        >
          <h3 className="text-2xl font-bold mb-4 text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
            {title}
          </h3>
          <div className="space-y-4 mb-6">
            {items.map((item, index) => {
              if (item.includes("$")) {
                const [serviceName, priceInfo] = item.split(": $");
                const serviceKey = Object.keys(prices).find(
                  (key) =>
                    prices[key].usd ===
                    parseInt(priceInfo?.split("/")[0] || priceInfo),
                );

                return (
                  serviceKey && (
                    <div
                      key={index}
                      className="flex items-center gap-3 justify-between"
                    >
                      <label className="flex items-start gap-3 cursor-pointer flex-1">
                        <input
                          type="checkbox"
                          className="mt-1.5 h-5 w-5 rounded border-gray-300 text-purple-500 focus:ring-purple-500"
                          checked={selectedServices.includes(serviceKey)}
                          onChange={() => handleServiceSelect(serviceKey)}
                        />
                        <span className="text-lg">{serviceName}</span>
                      </label>
                      <span className="text-lg font-semibold whitespace-nowrap">
                        ${prices[serviceKey].usd}
                        {formatPeriod(prices[serviceKey].period)}
                      </span>
                    </div>
                  )
                );
              }

              return (
                <div key={index} className="flex items-start gap-3">
                  <CheckCircle2
                    size={24}
                    className="text-green-400 mt-1 flex-shrink-0"
                  />
                  <span className="text-lg">{item}</span>
                </div>
              );
            })}
          </div>

          {children}

          {selectedServices.length > 0 && (
            <div className="text-center space-y-4 mt-8 pt-6 border-t border-blue-400/20">
              <div className="text-2xl font-bold">
                Total: ${totalPrice.usd.toFixed(2)}
                <div className="text-lg mt-1">
                  or {totalPrice.cro.toFixed(4)} CRO
                </div>
              </div>
              <button
                onClick={() => {
                  if (!globalAccount) {
                    alert(
                      "Please connect your wallet first to proceed with the payment.",
                    );
                    return;
                  }
                  setShowForm(true);
                }}
                className="w-full bg-gradient-to-r from-blue-500 to-purple-500 text-white font-bold py-3 px-6 rounded-lg
                hover:opacity-90 transition-all duration-300 flex items-center justify-center gap-2"
              >
                {!globalAccount ? (
                  <>
                    Connect Wallet to Order
                    <Wallet size={20} className="ml-2" />
                  </>
                ) : (
                  "Continue to Order"
                )}
              </button>
              {!globalAccount && (
                <div className="text-sm text-gray-400">
                  Wallet connection required to proceed with payment
                </div>
              )}
            </div>
          )}
        </div>
      );
    };

    if (loading) {
      return (
        <div className="container mx-auto px-4 pt-24 text-center">
          <div className="text-2xl animate-pulse">Loading prices...</div>
        </div>
      );
    }

    return (
      <div className="container mx-auto px-4 pt-24 max-w-6xl">
        <h2 className="text-4xl font-bold mb-12 text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
          Service Pricing
        </h2>

        {error && (
          <div className="mb-8 p-4 bg-red-900/50 text-red-200 rounded-lg text-center">
            {error}
          </div>
        )}

        {formError && (
          <div className="mb-8 p-4 bg-red-900/50 text-red-200 rounded-lg text-center">
            {formError}
          </div>
        )}

        {formSubmitted && (
          <div className="mb-8 p-4 bg-green-900/50 text-green-200 rounded-lg text-center">
            Order submitted successfully!
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <PriceCard
            title="Free V1 Version"
            items={[
              "Complete Web3 project development",
              "Hosting on FR33D3V URL",
              "5 advertising spaces included",
              "Essential features and functionalities",
            ]}
          />
          <PriceCard
            title="Premium Options"
            items={[
              "Custom domain: $75",
              "Basic support: $100/month",
              "Advanced support: $300/month",
              "V2 Upgrade: $500",
            ]}
          />
          <PriceCard
            title="Advertising Spaces"
            items={[
              "1 unit: $50/month",
              "5 units pack: $175/month",
              "Starter Pack*: $199 one-time",
            ]}
          >
            <div className="mt-4 pt-4 text-center text-sm text-gray-400 border-t border-blue-400/20">
              * Starter Pack includes 3 advertising units and credit removal
              feature
            </div>
          </PriceCard>
          <PriceCard
            title="Customization"
            items={["Credit removal: $150 one-time", "Ads removal: $75"]}
          />
        </div>

        <div className="mt-8 text-center text-sm text-gray-400">
          * Prices in CRO are updated every 5 minutes based on current market
          rates
        </div>

        {transactionHash && (
          <div className="mt-4 p-4 bg-green-900/50 text-green-200 rounded-lg text-center">
            Transaction confirmed! View on Cronoscan:
            <a
              href={`https://cronoscan.com/tx/${transactionHash}`}
              target="_blank"
              rel="noopener noreferrer"
              className="ml-2 underline hover:text-green-300"
            >
              {transactionHash}
            </a>
          </div>
        )}
      </div>
    );
  };

  const ProjectsPage = () => {
    const ProjectCard = ({ project }) => (
      <div className="bg-gradient-to-br from-blue-800/50 to-purple-800/50 rounded-xl overflow-hidden transition-all duration-300 hover:scale-105 hover:shadow-xl w-full max-w-md">
        <img
          src={project.image}
          alt={project.name}
          className="w-full h-85 object-cover"
        />
        <div className="p-6">
          <div className="flex justify-between items-start mb-4">
            <h3 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
              {project.name}
            </h3>
            <span className="px-3 py-1 text-sm rounded-full bg-blue-500/20 text-blue-300">
              {project.status}
            </span>
          </div>
          <p className="text-gray-300 mb-6">{project.description}</p>
          <a
            href={project.link}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center gap-2 bg-gradient-to-r from-blue-500 to-purple-500 text-white px-4 py-2 rounded-lg hover:opacity-90 transition-all duration-300"
          >
            Visit Project <ExternalLink size={16} />
          </a>
        </div>
      </div>
    );

    const ProjectGrid = ({ projects, title }) => {
      if (!projects || projects.length === 0) return null;

      const getGridClass = (count) => {
        switch (count) {
          case 1:
            return "flex justify-center";
          case 2:
            return "grid grid-cols-1 md:grid-cols-2 gap-8 place-items-center max-w-4xl mx-auto";
          default:
            return "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 place-items-center";
        }
      };

      return (
        <div className="mb-16">
          <h3 className="text-2xl font-bold mb-8 text-center">{title}</h3>
          <div className={getGridClass(projects.length)}>
            {projects.map((project) => (
              <ProjectCard key={project.id} project={project} />
            ))}
          </div>
        </div>
      );
    };

    return (
      <div className="container mx-auto px-4 pt-24 pb-16">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
            Our Projects
          </h2>
          <p className="text-xl text-gray-300">
            Discover our ongoing, completed projects and interactive demos
          </p>
        </div>

        {PROJECTS_DATA.ongoing.length > 0 && (
          <ProjectGrid
            projects={PROJECTS_DATA.ongoing}
            title="Ongoing & Completed Projects"
          />
        )}

        {PROJECTS_DATA.demo.length > 0 && (
          <ProjectGrid projects={PROJECTS_DATA.demo} title="Demo Projects" />
        )}
      </div>
    );
  };

  const ContactPage = () => {
    const [status, setStatus] = useState("");
    const [file, setFile] = useState(null);
    const [formState, setFormState] = useState({
      name: "",
      username: "",
      email: "",
      tokenName: "",
      tokenSymbol: "",
      contractAddress: "",
      chain: "",
      discord: "",
      website: "",
      twitter: "",
      medium: "",
      telegram: "",
      description: "",
    });
    const fileInputRef = useRef(null);

    const handleInputChange = useCallback((e) => {
      const { name, value } = e.target;
      setFormState((prev) => ({
        ...prev,
        [name]: value,
      }));
    }, []);

    const validateForm = () => {
      if (!formState.name || !formState.email || !formState.description) {
        setStatus("Please fill in all required fields");
        return false;
      }
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formState.email)) {
        setStatus("Please enter a valid email address");
        return false;
      }
      return true;
    };

    const handleFileChange = useCallback((e) => {
      const selectedFile = e.target.files?.[0];
      const acceptedTypes = [
        "image/webp",
        "image/jpeg",
        "image/png",
        "image/gif",
      ];
      const maxSize = 5 * 1024 * 1024; // 5MB

      if (!selectedFile) return;

      if (!acceptedTypes.includes(selectedFile.type)) {
        setStatus("Please upload a valid image file (WEBP, JPEG, PNG, or GIF)");
        return;
      }

      if (selectedFile.size > maxSize) {
        setStatus("File size should be less than 5MB");
        return;
      }

      setFile(selectedFile);
      setStatus("");
    }, []);

    const handleSubmit = async (e) => {
      e.preventDefault();
      if (!validateForm()) return;

      setStatus("Sending...");

      const formData = new FormData();
      formData.append("form-name", "contact-form");

      Object.keys(formState).forEach((key) => {
        formData.append(key, formState[key]);
      });

      if (file) {
        formData.append("file", file);
      }

      try {
        const response = await fetch("/", {
          method: "POST",
          body: formData,
          headers: { Accept: "application/json" },
        });

        if (!response.ok) {
          throw new Error(`Form submission failed: ${response.status}`);
        }

        setFormState({
          name: "",
          username: "",
          email: "",
          tokenName: "",
          tokenSymbol: "",
          contractAddress: "",
          chain: "",
          discord: "",
          website: "",
          twitter: "",
          medium: "",
          telegram: "",
          description: "",
        });
        setFile(null);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        setStatus("Message sent successfully!");
      } catch (error) {
        console.error("Form submission error:", error);
        setStatus("Failed to send message. Please try again.");
      }
    };

    return (
      <div className="container mx-auto px-4 pt-24 pb-16">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
            Contact Us
          </h2>
          <p className="text-xl text-gray-300">
            Have questions or want to start your project? Don't hesitate to
            contact us!
          </p>
        </div>

        <form
          name="contact-form"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          encType="multipart/form-data"
          onSubmit={handleSubmit}
          className="max-w-2xl mx-auto space-y-6"
        >
          <input type="hidden" name="form-name" value="contact-form" />
          <div hidden>
            <input name="bot-field" />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <input
              type="text"
              name="name"
              placeholder="Name *"
              required
              className="bg-blue-900/30 text-white rounded-lg px-4 py-3 w-full focus:outline-none focus:ring-2 focus:ring-purple-500 transition-all duration-300"
              value={formState.name}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="username"
              placeholder="Discord or Telegram username *"
              required
              className="bg-blue-900/30 text-white rounded-lg px-4 py-3 w-full focus:outline-none focus:ring-2 focus:ring-purple-500 transition-all duration-300"
              value={formState.username}
              onChange={handleInputChange}
            />
          </div>

          <input
            type="email"
            name="email"
            placeholder="Email *"
            required
            className="bg-blue-900/30 text-white rounded-lg px-4 py-3 w-full focus:outline-none focus:ring-2 focus:ring-purple-500 transition-all duration-300"
            value={formState.email}
            onChange={handleInputChange}
          />

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <input
              type="text"
              name="tokenName"
              placeholder="Token Name (optional)"
              className="bg-blue-900/30 text-white rounded-lg px-4 py-3 w-full focus:outline-none focus:ring-2 focus:ring-purple-500 transition-all duration-300"
              value={formState.tokenName}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="tokenSymbol"
              placeholder="Token Symbol (optional)"
              className="bg-blue-900/30 text-white rounded-lg px-4 py-3 w-full focus:outline-none focus:ring-2 focus:ring-purple-500 transition-all duration-300"
              value={formState.tokenSymbol}
              onChange={handleInputChange}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <input
              type="text"
              name="contractAddress"
              placeholder="Contract Address (optional)"
              className="bg-blue-900/30 text-white rounded-lg px-4 py-3 w-full focus:outline-none focus:ring-2 focus:ring-purple-500 transition-all duration-300"
              value={formState.contractAddress}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="chain"
              placeholder="Chain (optional)"
              className="bg-blue-900/30 text-white rounded-lg px-4 py-3 w-full focus:outline-none focus:ring-2 focus:ring-purple-500 transition-all duration-300"
              value={formState.chain}
              onChange={handleInputChange}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <input
              type="url"
              name="website"
              placeholder="Website (optional)"
              className="bg-blue-900/30 text-white rounded-lg px-4 py-3 w-full focus:outline-none focus:ring-2 focus:ring-purple-500 transition-all duration-300"
              value={formState.website}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="discord"
              placeholder="Discord (optional)"
              className="bg-blue-900/30 text-white rounded-lg px-4 py-3 w-full focus:outline-none focus:ring-2 focus:ring-purple-500 transition-all duration-300"
              value={formState.discord}
              onChange={handleInputChange}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <input
              type="text"
              name="twitter"
              placeholder="Twitter / X (optional)"
              className="bg-blue-900/30 text-white rounded-lg px-4 py-3 w-full focus:outline-none focus:ring-2 focus:ring-purple-500 transition-all duration-300"
              value={formState.twitter}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="medium"
              placeholder="Medium (optional)"
              className="bg-blue-900/30 text-white rounded-lg px-4 py-3 w-full focus:outline-none focus:ring-2 focus:ring-purple-500 transition-all duration-300"
              value={formState.medium}
              onChange={handleInputChange}
            />
          </div>

          <input
            type="text"
            name="telegram"
            placeholder="Telegram (optional)"
            className="bg-blue-900/30 text-white rounded-lg px-4 py-3 w-full focus:outline-none focus:ring-2 focus:ring-purple-500 transition-all duration-300"
            value={formState.telegram}
            onChange={handleInputChange}
          />

          <textarea
            name="description"
            placeholder="Project Description *"
            required
            rows={6}
            className="bg-blue-900/30 text-white rounded-lg px-4 py-3 w-full focus:outline-none focus:ring-2 focus:ring-purple-500 transition-all duration-300"
            value={formState.description}
            onChange={handleInputChange}
          />

          <div className="border-2 border-dashed border-blue-500/50 rounded-lg p-8 text-center hover:border-purple-500/50 transition-all duration-300">
            <input
              type="file"
              name="file"
              accept="image/webp,image/jpeg,image/png,image/gif"
              onChange={handleFileChange}
              className="hidden"
              id="fileUpload"
              ref={fileInputRef}
            />
            <label
              htmlFor="fileUpload"
              className="cursor-pointer flex flex-col items-center space-y-2"
            >
              <Upload size={40} className="text-blue-400" />
              <span className="text-gray-300">
                Click to upload or drag and drop
              </span>
              <span className="text-sm text-gray-400">
                WEBP, JPEG, PNG, or GIF (max 5MB)
              </span>
              {file && (
                <span className="text-green-400 mt-2">
                  Selected: {file.name}
                </span>
              )}
            </label>
          </div>

          <button
            type="submit"
            className="w-full bg-gradient-to-r from-blue-500 to-purple-500 text-white font-bold py-4 px-6 rounded-lg
            hover:opacity-90 transition-all duration-300 flex items-center justify-center gap-2 disabled:opacity-50"
            disabled={status === "Sending..."}
          >
            <span>{status === "Sending..." ? "Sending..." : "Send"}</span>
            <ArrowUpCircle size={20} />
          </button>

          {status && (
            <div
              className={`p-4 rounded-lg text-center transition-all duration-300 ${
                status.includes("error") || status.includes("Please")
                  ? "bg-red-900/50 text-red-200"
                  : status === "Sending..."
                    ? "bg-blue-900/50 text-blue-200"
                    : "bg-green-900/50 text-green-200"
              }`}
            >
              {status}
            </div>
          )}
        </form>
      </div>
    );
  };

  const Footer = () => {
    const handleNavigation = (pageId) => {
      if (pageId === "tools") {
        window.open("https://fr33tools.netlify.app/", "_blank");
      } else {
        setCurrentPage(pageId);
      }
    };

    return (
      <footer className="bg-gradient-to-r from-blue-900/90 to-purple-900/90 backdrop-blur-md mt-16">
        <div className="container mx-auto px-4 py-12">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div className="space-y-4">
              <h3 className="text-xl font-bold bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
                FR33D3V
              </h3>
              <p className="text-gray-300">
                Your go-to partner for innovative Web3 project development on
                Cronos blockchain
              </p>
            </div>
            <div>
              <h4 className="text-lg font-semibold mb-4">Quick Links</h4>
              <ul className="space-y-2">
                {menuItems.map((item) => (
                  <li key={item.id}>
                    <button
                      onClick={() => handleNavigation(item.id)}
                      className="text-gray-300 hover:text-white flex items-center gap-2 transition-colors duration-300"
                    >
                      <ChevronRight size={16} />
                      {item.title}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h4 className="text-lg font-semibold mb-4">Community</h4>
              <div className="flex space-x-4">
                <button
                  onClick={() =>
                    window.open("https://twitter.com/FR33D3V", "_blank")
                  }
                  className="text-gray-300 hover:text-white transition-colors duration-300"
                  aria-label="X (Twitter)"
                >
                  <FontAwesomeIcon icon={faXTwitter} size="2x" />
                </button>
                <button
                  onClick={() => window.open("https://t.me/FR33_D3V", "_blank")}
                  className="text-gray-300 hover:text-white transition-colors duration-300"
                  aria-label="Telegram"
                >
                  <Send size={30} />
                </button>
              </div>
            </div>
            <div>
              <div className="flex items-center mb-4">
                <img
                  src="https://substackcdn.com/image/fetch/e_trim:10:white/e_trim:10:transparent/h_72,c_limit,f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F963c3ca6-0df7-4aaf-8377-ff3828508206_666x375.png"
                  alt="Logo"
                  className="ml-2"
                  style={{ height: "30px", width: "90px", marginLeft: "5px" }}
                />
              </div>
              <button
                onClick={() =>
                  window.open(
                    "https://buildoncronos.substack.com/embed",
                    "_blank",
                  )
                }
                className="max-w-[200px] w-full bg-gradient-to-r from-blue-500 to-purple-500 text-white px-4 py-2 rounded-lg hover:opacity-90 transition-duration-300 flex items-center justify-center"
              >
                <span>Join Newsletter</span>
              </button>
            </div>
          </div>
          <div className="border-t border-gray-700 mt-8 pt-8 text-center text-gray-400">
            <p>© 2024 FR33D3V. All rights reserved.</p>
          </div>
        </div>
      </footer>
    );
  };

  if (isLoading) {
    return (
      <div className="h-screen flex items-center justify-center bg-gradient-to-r from-blue-900 to-purple-900">
        <img
          src="/img/logo.png"
          alt="FR33D3V"
          className="w-32 h-32 animate-pulse"
        />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-900 to-purple-900 text-white">
      <Navigation />

      {currentPage === "home" && <HomePage />}
      {currentPage === "rules" && <RulesPage />}
      {currentPage === "projects" && <ProjectsPage />}
      {currentPage === "pricing" && <PricingPage />}
      {currentPage === "contact" && <ContactPage />}

      <Footer />

      <div className="fixed top-0 left-0 w-full h-full pointer-events-none">
        {[...Array(20)].map((_, i) => (
          <div
            key={i}
            className="absolute w-2 h-2 bg-white rounded-full opacity-20"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              animation: `float ${3 + Math.random() * 4}s ease-in-out infinite`,
              animationDelay: `${Math.random() * 5}s`,
            }}
          />
        ))}
      </div>

      <style>{`
        @keyframes float {
          0%, 100% {
            transform: translateY(0px);
            opacity: 0.2;
          }
          50% {
            transform: translateY(-20px);
            opacity: 0.5;
          }
        }
      `}</style>
    </div>
  );
};

export default FR33D3V;